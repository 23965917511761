import { Button } from '@mui/material';
import React from 'react';

function ButtonFunc ( props )
{

  const customize = {
    'width':props.width,
    'height':props.height,
    'borderRadius':props.borders,
    'color':props.color,
    'background':props.background,
    'className': props.className,
    'border':props.border,
    'marginRight':'16px',
    'fontSize':props.fontSize,
    '&:hover':{
      background:props.background,
      color:props.color
    }
  };

  return(
    <>
      <Button variant="contained" className={props.className} onClick={props.onClick} sx={customize} >{props.children}</Button>
    </>
  );
}

export default ButtonFunc;

import '../styles/get-quote.scss';

import { gql,useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { navigate } from 'gatsby';
import React, { useEffect,useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';

import info from '../assets/images/info.svg';
import Button from '../components/Button';
import Maps from '../components/google';
import InputField from '../components/InputField';
import Layout from '../components/layout';
import Upload from '../components/upload';
import { getZipCode } from '../helpers/address';


const ADD_CUSTOMER = gql`
mutation InsertAgentportalcustomers(
  $auth0_id: String!,
  $address: String = "",
  $email: String = "",
  $full_name: String = "",
  $first_name: String!,
  $last_name: String!,
  $home_premium: String = "",
  $life_premium: String = "",
  $insurance_status: String = "lead",
  $insurance_types: String = "",
  $cta_url: String!,
  $phone: String = "",
  $profile_url: String = "",
  $zip: String = "",
  $city: String = "",
  $stateCode: String = "",
  $street_address: String = ""
  ) {
  insert_agentportalcustomers_one(object: {
    address: $address,
    auth0_id: $auth0_id,
    email: $email,
    full_name: $full_name,
    insurance_types: $insurance_types,
    first_name: $first_name,
    last_name: $last_name,
    home_premium: $home_premium,
    life_premium: $life_premium,
    phone: $phone,
    profile_url: $profile_url,
    zip: $zip,
    street_address: $street_address,
    statecode: $stateCode,
    city: $city,
    cta_url: $cta_url}) {
    address
    auth0_id
    email
    full_name
    home_premium
    life_premium
    insurance_status
    insurance_types
    cta_url
    phone
    profile_url
    viewed
    zip
  }
}
`;

const PROD_ADD_CUSTOMER = gql`
mutation InsertAgentportalcustomers(
  $auth0_id: String!,
  $address: String = "",
  $email: String = "",
  $full_name: String = "",
  $home_premium: String = "",
  $first_name: String!,
  $last_name: String!,
  $life_premium: String = "",
  $insurance_status: String = "lead",
  $insurance_types: String = "",
  $cta_url: String!,
  $phone: String = "",
  $city: String = "",
  $stateCode: String = "",
  $street_address: String = "",
  $zip: String = ""
  ) {
    insert_agentportalcustomers_one: prod_insert_agentportalcustomers_one(object: {
    address: $address,
    auth0_id: $auth0_id,
    email: $email,
    full_name: $full_name,
    insurance_types: $insurance_types,
    home_premium: $home_premium,
    life_premium: $life_premium,
    first_name: $first_name,
    last_name: $last_name,
    phone: $phone,
    profile_url: $profile_url,
    zip: $zip,
    street_address: $street_address,
    statecode: $stateCode,
    city: $city,
    cta_url: $cta_url}) {
    address
    auth0_id
    email
    full_name
    home_premium
    life_premium
    insurance_status
    insurance_types
    cta_url
    phone
    profile_url
    viewed
    zip
  }
}
`;


const GET_QUOTE = gql`
mutation MyMutation(
  $city: String = ""
  $country: String = ""
  $email: String = ""
  $first_name: String = ""
  $formatted_address: String = ""
  $last_name: String = ""
  $phone: String = ""
  $state: String = ""
  $street: String = ""
  $zip: String = ""
) {
  quickQuote(
    arg1: {
      city: $city
      country: $country
      email: $email
      first_name: $first_name
      formatted_address: $formatted_address
      last_name: $last_name
      phone: $phone
      state: $state
      street: $street
      zip: $zip
    }
  ) {
    home {
      actual_address
      cta_url
      message
      monthly_quote_amt
      status
    }
    message
    status
    status_code
  }
}
`;


export default function GetQuote(){

  const { isLoading: authLoading, isAuthenticated } = useAuth0();
  useEffect( () => {
    if( !( authLoading || isAuthenticated ) ){
      navigate( '/login' );
    }
  }, [authLoading] );

  const [type, setType] = useState( '1003' );
  const [file, uploadFile] = useState( '' );
  const [acceptedTypes, setAccepted] = useState( '.fnm' );

  // GetQuickQuote API related coding started here.
  
 
  const [address, setaddress] = useState( '' );
  const [home_inputs, setHomeInputs ] = useState( { zip: '', city: '', stateCode: '', street: '', country: '' }  );
 
  let quoteData = {
    cta_url: '',
    home_quote: ''
  };

  const [addCustomer, { data, loading, error }] = useMutation( process.env.GATSBY_ENV == 'PROD' ? PROD_ADD_CUSTOMER : ADD_CUSTOMER );
  const [getQuickQuote, { dataQuote, loadingQuote, errorQuote }] = useMutation( GET_QUOTE );
  
  // if (loadingQuote) return <p>Loading Quote..</p>;
  // if (errorQuote) return   <p>Error Loading Quote!</p>;

  function QuickGetQuote(){
    getQuickQuote( {
      variables: {
        city: home_inputs.city,
        country: home_inputs.country,
        state: home_inputs.stateCode,
        street: home_inputs.street,
        zip: zipCode
      }

    } ).then( res => {
      quoteData = {
        cta_url: res.data?.quickQuote?.home?.[0]?.cta_url || '',
        home_quote: res.data?.quickQuote?.home?.[0]?.monthly_quote_amt || 0
      };

    } );
  }


  function GetQuickQuote( e ) {
    e.preventDefault();
    QuickGetQuote();
  }

  // GetQuickQuote API related coding end here. 

  useEffect( () => {
    if( type == '1003' ){
      setAccepted( '.fnm' );
    } else {
      setAccepted( '.xml' );
    }
  }, [type] );

  
  // useEffect( () => {
  //   async function handleAddressSelect( address ){
  //     if( address ) {
  //       const results = await geocodeByAddress( address );
  
  //       const { zip , stateName } = getZipCode( results );
  //       setZip( zip );
  //       setStateName( stateName );
  //     }
  //   }
  //   handleAddressSelect();
  // }, [] );


  async function handleAddressSelect( address ){
    const results = await geocodeByAddress( address );

    const { zip , stateName } = getZipCode( results );
    setZip( zip );
    setStateName( stateName );
  }

  async function getQuote( e ){
    e.preventDefault();

    if( type === 'mismo' ) {
      let formData = new FormData();
      formData.append(
        'image', file, file.name.replace( / /g, '_' )
      );

      const resp = await  fetch( 'https://app.getmybubble.co/api/upload/private', {
        method: 'POST',
        body: formData
      } );
      const { url } = await resp.json();

      const redirect = await  fetch( 'https://app.getmybubble.co/api/home-quote/xmltojs', {
        method: 'POST',
        body: JSON.stringify( {
          filename: url
        } ),
        headers: {
          'content-type': 'application/json'
        }
      } );

      const { data } = await redirect.json();
      window.open( data, '_blank' );
    } else {
      const url = 'https://newapp.getmybubble.co/fnm/test.php';
      let formData = new FormData();
      formData.append(
        'fileToUpload', file, file.name
      );

      const resp = await  fetch( url, {
        method: 'POST',
        body: formData
      } );

      const data = await resp.text();
      window.open( data?.split( '\'' )[1], '_blank' );

    }
  }

  const [zipCode, setZip] = useState( '' );
  const [stateName, setStateName] = useState( '' );

  function fileAdded( files ){
    const file = files[0];
    uploadFile( file );
  }

  return(
    <>
      <Layout>
        <Container maxWidth="xl">

          <Grid container spacing={2} >


            <Grid item lg={12} xs={12} >
              <p className="quotes">Get Quote</p>

              <p className="get-quotes-under">Enter address or Upload your 1003/MISMO files below</p>

              <Grid className='address-fields' item xs={12} container>

                <Grid item xs={12} lg={6} className="mb-8">
                  <div className='address-input-container'>

                    <p className="field-name">Enter Address</p>

                    <Maps placeholder={'Type Here'} getAddress={handleAddressSelect}/>
                  </div>
                </Grid>

                <Grid item xs={12} container spacing={2} className="mb-16">
                  <Grid item xs={12} lg={3} >

                    <div className='zip-state'>

                      <p className="zip-state-name">Zipcode</p>

                      <InputField
                        placeholder="xxxxxx"
                        multi={false}
                        value={zipCode}
                        width="100%"
                        height="18px"
                      ></InputField>


                    </div>


                  </Grid>

                  <Grid item xs={12} lg={3}>

                    <div className='zip-state'>

                      <p className="zip-state-name">State</p>

                      <InputField
                        placeholder="xxxxxx"
                        multi={false}
                        value={stateName}
                        width="100%"
                        height="18px"
                      ></InputField>


                    </div>


                  </Grid>
                </Grid>

                <Grid item xs={12} lg={3}>

                  <Button Button onClick={GetQuickQuote} className="btnstyle" route="/" width="100%" height="44px" borders="50px" fontSize="16px" color="#fff" background=" linear-gradient(270deg, #FF9715 0%, #FF7007 100%)" border="1px solid #ff7007">
                    Get Quote
                  </Button>
                </Grid>

                <Grid item xs={6} lg={9}></Grid>

                <Grid item xs={12} lg={8} container alignItems="center" alignContent="center" className="borderspace">
                  <Grid item xs={5.5} lg={5.5}>
                    <hr></hr>
                  </Grid>

                  <Grid item xs={1} lg={1}>
                    <p style={{ marginBottom:'0px',fontSize:'14px',color:'#646464',textAlign:'center' }}>OR</p>
                  </Grid>

                  <Grid item xs={5.5} lg={5.5}>
                    <hr></hr>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} >
                  <p className='file-text'>Upload 1003/MISMO <span className='semi-text'>(choose one)</span></p>
                </Grid>


                <Grid item xs={12} lg={6} container>
                  <Grid item xs={6} lg={6}>
                    <Button onClick={() => setType( '1003' )} route="/"borders="50px" className={type == '1003' ? 'upload upload-active' : 'upload inactive'} >1003</Button>

                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Button onClick={() => setType( 'mismo' )} route="/" borders="50px" className={type == 'mismo' ? 'upload upload-active' : 'upload inactive'}  >MISMO&nbsp;<img src={info}  alt=""/></Button>

                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} >
                  <p className='file-text'>Attach Documents</p>
                </Grid>

                <Grid item xs={12} lg={6} >
                  <Upload acceptedTypes={acceptedTypes} fileAdded={fileAdded}/>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={3} className="mb-80 mt-24">
                <Button onClick={getQuote} className="btnstyle" route="/" width="100%" height="44px" borders="50px" fontSize="16px" color="#fff" background=" linear-gradient(270deg, #FF9715 0%, #FF7007 100%)" border="1px solid #ff7007">
                  Upload now
                </Button>
              </Grid>

            </Grid>


          </Grid>

        </Container>
      </Layout>
    </>
  );
}

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import  makeStyles  from '@mui/styles/makeStyles';
import * as React from 'react';


export default function InputField( props ) {

  const useStyles = makeStyles( {
    header: {
      marginBottom:'10px'
    },
    textField:{
      height:'100px'
    } } );

  const cssChange = useStyles();
  const inputProps = {
    disableUnderline: true,
    style: {
      borderRadius: 28,
      backgroundColor:'white',
      border:props.border,
      width:props.width,
      height:props.height,
      fontSize:'16px',
      fontWeight:300,
      fontStyle:'normal',
      color: '#afafaf',
      paddingTop:'0px'
    }

  };

  return (
    <Grid>
      <TextField
        className={cssChange.header + ' ' + props.className}
        fullWidth={true} variant="filled"
        label={props.label}
        placeholder={props.placeholder}
        multiline={props.multi}
        rows={props.rows}
        onChange={props.onChange || null}
        maxRows={props.rows}
        defaultValue={props.value}
        value={props.value}
        InputProps={inputProps} /><br/>

    </Grid>


  );
}
